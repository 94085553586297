/* eslint-disable no-param-reassign */
/* eslint-disable no-new */
/* eslint-disable max-len */
import moment from 'moment-timezone';

import { daysFullLoklaiseKeys, daysShortLoklaiseKeys, monthsFullLokaliseKeys, monthsShortLokaliseKeys } from './flightpath/config';
import { formatNumberToTime } from './flightpath/wearTime';
import { getCookie } from './storage';

import { pageviewEvent } from '../helpers/wow-segment';
import { countryDialCodeData } from '../language.config';
import { colorOrder } from '../links.config';

const isCheckoutEnabled = process.env.NEXT_PUBLIC_CHECKOUT_ENABLED === 'TRUE';

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isValidName = (name) => {
  // const invalidNameRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\d]+/g;
  const invalidNameRegex = /[!@#$%^&*_+\=[\]{};:\\|.<>]+/g;
  return name && name.replace(/ /g, '').length && !invalidNameRegex.test(name);
};

export const getAllProducts = (productsData) => {
  const products = [];
  Object.values(productsData).forEach((product) => {
    Object.values(product.colorValues).forEach((value) => {
      products.push({
        category: product.category,
        countryCode: product.country,
        currency: product.currency,
        categoryId: product.id,
        categoryName: product.name,
        subCategory: product.subCategory,
        color: value.title,
        productId: value.id,
        page: product.page,
        sku: value.sku,
        availableQty: value.availableQty,
        barcode: value.barcode,
        createdAt: value.createdAt,
        description: value.description,
        name: value.name,
        price: value.price
      });
    });
  });
  return products;
};

export const validateNumberField = (input) => {
  const regex = /^(([0-9])*([a-z A-Z])+([0-9])*)*$/;
  const isValid = regex.test(input);
  return !isValid && !(input < 0);
};

export const dateFormats = {
  YYYYDDMM: 'YYYY-MM-DD'
};

export const hyphenDateFormat = 'YYYY-MM-DD';

export const validatedPrice = (price) => (price < 0 ? 0 : price);

export const validatePassword = (password) => {
  const regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/;
  return regx.test(password);
};

export const getCountryCodeByIsd = (code) => {
  let country = '';
  Object.keys(countryDialCodeData).forEach((key) => {
    if (countryDialCodeData[key].dialCode === code) country = key;
  });
  return country;
};

export const useUtmParam = (onsiteSource) => {
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'gclid'
  ];
  let query = `?onsite_source=${onsiteSource}&`;
  utmParams.forEach((utm) => {
    if (getCookie(utm)) {
      query = `${query}${utm}=${getCookie(utm)}&`;
    }
  });
  if (query.endsWith('&')) query = query.replace(new RegExp(`&$`), '');
  return query === '?' ? '' : query;
};

export const gumletLoader = ({ src, width, quality }) => (process.env.NEXT_PUBLIC_GUMLET_URL
  ? `${process.env.NEXT_PUBLIC_GUMLET_URL}${src}?w=${width}&q=${
    quality || 75
  }`
  : src);
export const gumletAWSLoader = ({ src, width, quality }) => {
  if (src) {
    const imgRoot = src.split('/ecommerce/cms')[1];
    return process.env.NEXT_PUBLIC_GUMLET_AWS_URL
      ? `${process.env.NEXT_PUBLIC_GUMLET_AWS_URL}${imgRoot}?w=${width}&q=${
        quality || 85
      }`
      : src;
  }
  return '';
};
export const gumletAWSClean = (src) => {
  if (src) {
    return src.replace('/ecommerce/cms', '');
  }
  return '';
};

export const loadGumlet = () => {
  window.GUMLET_CONFIG = {
    hosts: [
      {
        current: process.env.NEXT_PUBLIC_BASE_URL,
        gumlet: process.env.NEXT_PUBLIC_GUMLET_URL
      },
      {
        current: process.env.NEXT_PUBLIC_AWS_URL,
        gumlet: process.env.NEXT_PUBLIC_GUMLET_AWS_URL
      }
    ],
    lazy_load: true
  };
  (function () {
    const d = document;
    const s = d.createElement('script');
    s.src = 'https://cdn.gumlet.com/gumlet.js/2.1/gumlet.min.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  }());
};

export const COUNTRY_TAX_CONFIG = {
  SGD: { value: 9, tax: 'GST' },
  MYR: { value: 0 },
  HKD: { value: 0 },
  MOP: { value: 0 },
  TWD: { value: 5, tax: 'VAT' },
  THB: { value: 7, tax: 'VAT' },
  VND: { value: 5, tax: 'VAT' },
  JPY: { value: 0 }
};

export const INTEGRATED_PAYMENT_MODES = {
  TWD: ['PAYMENT_CARD', 'ECPAY'],
  VND: [],
  HKD: ['PAYMENT_CARD', 'ZENPAY', 'HOOLAH', 'ATOME', 'TWOC2P', 'SHOPEE_PAY', 'SPAYLATER'],
  MYR: ['PAYMENT_CARD', 'ZENPAY', 'HOOLAH', 'ATOME', 'GRABPAY', 'GRABPAYPOSTPAID', 'GRABPAYLATER'],
  SGD: ['PAYMENT_CARD', 'ZENPAY', 'HOOLAH', 'ATOME', 'TWOC2P', 'GRABPAY', 'GRABPAYPOSTPAID', 'GRABPAYLATER', 'SHOPEE_PAY', 'SPAYLATER'],
  MOP: ['PAYMENT_CARD', 'ZENPAY', 'HOOLAH'],
  JPY: isCheckoutEnabled ? [] : ['PAYMENT_CARD'],
  THB: ['TWOC2P', 'SHOPEE_PAY', 'SPAYLATER']
};

export const MANUAL_PAYMENT_LINK_MODES = {
  TWD: ['GOMYPAY', 'HITRUST', 'CHAILEASE'],
  VND: [],
  HKD: ['IPP'],
  MYR: ['CHAILEASE'],
  SGD: [],
  MOP: [],
  JPY: isCheckoutEnabled ? ['SMARTPAY', 'PAYMENT_CARD'] : ['SMARTPAY'],
  THB: ['PAYMENT_CARD']
};

export const PAYMENT_MODES_CONFIG = {
  IPP: 'IPP',
  PAYMENT_CARD: 'Credit card/Debit card',
  SMARTPAY: 'Smart Pay',
  HITRUST: 'Hitrust',
  ECPAY: 'ECPay',
  GOMYPAY: 'GoMyPay',
  CHAILEASE: 'Chailease',
  ATOME: 'Atome',
  HOOLAH: 'Hoolah',
  TWOC2P: '2C2P',
  GRABPAY: 'Grab Pay',
  GRABPAYPOSTPAID: 'Grab Pay',
  GRABPAYLATER: 'Grab Pay',
  SHOPEE_PAY: '2C2P',
  SPAYLATER: '2C2P'
};

export const DEFAULT_ADDRESSES = {
  hk: {
    city: 'Hong Kong',
    district: 'North',
    area: 'North'
  },
  vn: {
    city: 'Hà Nội',
    district: 'Quận Ba Đình'
  }
};

export const DATE_FORMAT = 'DD MMM YYYY';

export const PAYMENT_MODES_LIST = {
  HOOLAH: 'HOOLAH',
  ATOME: 'ATOME',
  TWOC2P: 'TWOC2P',
  ZENYUM_INSTALMENT: 'ZENYUM_INSTALMENT',
  GRABPAY: 'GRABPAY',
  SHOPEE_PAY: 'SHOPEE_PAY',
  SPAYLATER: 'SPAYLATER',
  GRABPAYPOSTPAID: 'GRABPAYPOSTPAID',
  GRABPAYLATER: 'GRABPAYLATER'
};

export const initializeStoryReviews = (elementId, skus, lang, translations) => {
  new window.carouselInlineWidget(elementId, {
    /* Your REVIEWS.io account ID: */
    store: 'zenyum',
    sku: skus,
    lang,
    carousel_type: 'topHeader',
    styles_carousel: 'CarouselWidget--topHeader--withcards',

    /* Widget settings: */
    options: {
      general: {
        /* What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma. */
        review_type: 'product',
        /* Minimum number of reviews required for widget to be displayed */
        min_reviews: '0',
        /* Maximum number of reviews to include in the carousel widget. */
        max_reviews: '20',
        address_format: 'CITY, COUNTRY',
        /* Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false. */
        enable_auto_scroll: 10000
      },
      header: {
        /* Show overall rating stars */
        enable_overall_stars: true
      },
      reviews: {
        /* Show customer name */
        enable_customer_name: true,
        /* Show customer location */
        enable_customer_location: true,
        /* Show "verified review" badge */
        enable_verified_badge: true,
        enable_reviewer_recommends: false,
        /* Show "I recommend this product" badge (Only for product reviews) */
        enable_recommends_badge: false,
        /* Show photos attached to reviews */
        enable_photos: true,
        /* Show videos attached to reviews */
        enable_videos: true,
        /* Show when review was written */
        enable_review_date: true,
        /* Hide reviews written by the same customer (This may occur when customer reviews multiple products) */
        disable_same_customer: true,
        /* Minimum star rating */
        min_review_percent: 3,
        /* Show 3rd party review source */
        third_party_source: true,
        /* Hide reviews without comments (still shows if review has a photo) */
        hide_empty_reviews: true,
        /* Show product name */
        enable_product_name: true,
        /* Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2) */
        tags: lang,
        /* Show branch, only one input */
        branch: '',
        enable_branch_name: false
      },
      popups: {
        /* Make review items clickable (When they are clicked, a popup appears with more information about a customer and review) */
        enable_review_popups: true,
        /* Show "was this review helpful" buttons */
        enable_helpful_buttons: true,
        /* Show how many times review was upvoted as helpful */
        enable_helpful_count: true,
        /* Show share buttons */
        enable_share_buttons: true
      }
    },
    translations,
    styles: {
      /* Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted. */
      '--base-font-size': '16px',
      '--base-maxwidth': '100%',
      /* Logo styles: */
      '--reviewsio-logo-style': 'var(--logo-normal)',
      /* Star styles: */
      '--common-star-color': '#2CAAE2',
      '--common-star-disabled-color': ' rgba(0,0,0,0.25)',
      '--medium-star-size': '24px',
      '--small-star-size': '16px' /* Modal */,
      '--x-small-star-size': '16px',
      '--x-small-star-display': 'inline-flex',
      /* Header styles: */
      '--header-order': '1',
      '--header-width': '160px',
      '--header-bg-start-color': 'transparent',
      '--header-bg-end-color': 'transparent',
      '--header-gradient-direction': '135deg',
      '--header-padding': '0.5em',
      '--header-border-width': '0px',
      '--header-border-color': 'rgba(0,0,0,0.1)',
      '--header-border-radius': '0px',
      '--header-shadow-size': '0px',
      '--header-shadow-color': 'rgba(0, 0, 0, 0.1)',
      /* Header content styles: */
      '--header-star-color': '#2CAAE2',
      '--header-disabled-star-color': '#EBEBEB',
      '--header-heading-text-color': '#282829',
      '--header-heading-font-size': '20',
      '--header-heading-font-weight': 'inherit',
      '--header-heading-line-height': 'inherit',
      '--header-heading-text-transform': 'inherit',
      '--header-subheading-text-color': '#282829',
      '--header-subheading-font-size': '',
      '--header-subheading-font-weight': 'inherit',
      '--header-subheading-line-height': 'inherit',
      '--header-subheading-text-transform': 'inherit',
      /* Review item styles: */
      '--item-maximum-columns': '5' /* Must be 3 or larger */,
      '--item-background-start-color': '#FFFFFF',
      '--item-background-end-color': '#FFFFFF',
      '--item-gradient-direction': '135deg',
      '--item-padding': '1.5em',
      '--item-border-width': '0px',
      '--item-border-color': 'rgba(0,0,0,0.1)',
      '--item-border-radius': '10px',
      '--item-shadow-size': '10px',
      '--item-shadow-color': 'rgba(0,0,0,0.1)',
      /* Heading styles: */
      '--heading-text-color': '#282829',
      '--heading-text-font-weight': ' 600',
      '--heading-text-font-family': ' inherit',
      '--heading-text-line-height': ' 1.4',
      '--heading-text-letter-spacing': '0',
      '--heading-text-transform': 'none',
      /* Body text styles: */
      '--body-text-color': '#282829',
      '--body-text-font-weight': '400',
      '--body-text-font-family': ' inherit',
      '--body-text-line-height': ' 1.4',
      '--body-text-letter-spacing': '0',
      '--body-text-transform': 'none',
      /* Scroll button styles: */
      '--scroll-button-icon-color': '#282828',
      '--scroll-button-icon-size': '24px',
      '--scroll-button-bg-color': 'transparent',
      '--scroll-button-border-width': '0px',
      '--scroll-button-border-color': '#282829',
      '--scroll-button-border-radius': '60px ',
      '--scroll-button-shadow-size': '0px',
      '--scroll-button-shadow-color': '',
      '--scroll-button-horizontal-position': '0px',
      '--scroll-button-vertical-position': '0px',
      /* Badge styles: */
      '--badge-icon-color': '#2CAAE2',
      '--badge-icon-font-size': '16',
      '--badge-text-color': '#999',
      '--badge-text-font-size': '12px',
      '--badge-text-letter-spacing': 'inherit',
      '--badge-text-transform': 'inherit',
      /* Author styles: */
      '--author-font-size': 'inherit',
      '--author-font-weight': 'inherit',
      '--author-text-transform': 'inherit',
      /* Product photo or review photo styles: */
      '--photo-video-thumbnail-size': '60px',
      '--photo-video-thumbnail-border-radius': '0px',
      /* Popup styles: */
      '--popup-backdrop-color': 'rgba(40,40,41,0.85)',
      '--popup-color': '#FFFFFF',
      '--popup-star-color': '#2CAAE2',
      '--popup-disabled-star-color': '#EBEBEB',
      '--popup-heading-text-color': '#282829',
      '--popup-body-text-color': '#282829',
      '--popup-badge-icon-color': '#2CAAE2',
      '--popup-badge-icon-font-size': '16px',
      '--popup-badge-text-color': '#999',
      '--popup-badge-text-font-size': '12px',
      '--popup-border-width': '0px',
      '--popup-border-color': 'rgba(0,0,0,0.1)',
      '--popup-border-radius': '10px',
      '--popup-shadow-size': '0px',
      '--popup-shadow-color': 'rgba(0,0,0,0.1)',
      '--popup-icon-color': '#2CAAE2',
      /* Tooltip styles: */
      '--tooltip-bg-color': '#0E1311',
      '--tooltip-text-color': '#FFFFFF'
    }
  });
};

export const getProductSkusByPage = (pages, productData) => {
  const allProducts = getAllProducts(productData);
  const skuArray = allProducts
    .filter((product) => pages.includes(product.page))
    .map((product) => product.sku);
  return skuArray.join(';');
};

export const showContentCards = (elementId) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window?.analytics?.ready(() => {
      const feed = document.getElementById(elementId);
      if (window.appboy.getCachedContentCards().getUnviewedCardCount() > 0) {
        window.appboy.display.showContentCards(feed);
      }
    });
  }
};

export const convertToLocalTime = (data) => {
  const localtime = moment.tz(data.time, data.zone).format('LT');
  const date = moment.tz(data.time, data.zone).format('Do');
  const month = moment.tz(data.time, data.zone).format('MMM');
  const year = moment.tz(data.time, data.zone).format('YYYY');
  const timeValue = `${data.showCountryCode ? data.countryCode : ''} ${
    data.showTime ? `${localtime}, ` : ''
  } ${date} ${month} ${year}`;
  return timeValue;
};

export const sortColorOrderReverse = (variants) => {
  const productOrder = [
    'ZENYUM_ALIGNERS',
    'ZENYUMBRIGHT_REFILLGEL',
    'ZENYUMBRIGHT_REFILLGEL-C',
    'ZENFRESH_FLOSS',
    'ZENFRESH_NIGHT',
    'ZENFRESH_DAY',
    'ZENFRESH_DAYNIGHT',
    'ZENFRESH_SHIELD',
    'ZENYUMBRIGHT_14STRIPS',
    'ZENYUMBRIGHT_28STRIPS',
    'ZENYUMBRIGHT_STARTERKIT',
    'WATERFLOSSER_TIP',
    'WATERFLOSSER_PRO',
    'WATERFLOSSER_TIPBUNDLE',
    'SONICGO_SONICBUNDLE',
    'SONICGO_MINIPASTESET',
    'SONICGO_SONICBUNDLE',
    'SONICGO_BRUSHHEAD3PBUNDLE',
    'SONICGO_TRAVELSET',
    'SONICBRUSHHEAD_3P',
    'SONICBRUSH_SINGLE',
    'SONICBRUSH_COMBO3P',
    'SONICBRUSH_COMBO3PBOXCANDLE',
    'SONICBRUSH_COMBO3PPOUCH',
    'WATERFLOSSER_SONICBUNDLE',
    'BUNDLE_ZENFRESH',
    'ZENYUMSONIC_STARTERKIT',
    'BUNDLE_SMILERITUAL'
  ];
  variants.sort(
    (a, b) => colorOrder.indexOf(a.color) - colorOrder.indexOf(b.color)
  );
  variants.sort(
    (a, b) => productOrder.indexOf(a.productKey) - productOrder.indexOf(b.productKey)
  );
  return variants.reverse();
};

export const screenTimeEvent = (entryTime, title, country) => {
  const now = new Date().getTime();
  const timeSpend = now - entryTime;
  const minutes = Math.floor((timeSpend % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeSpend % (1000 * 60)) / 1000);
  pageviewEvent(title, country, `${minutes} minute ${seconds} seconds`);
  return null;
};

export const analyticProductDataConfig2 = (data) => {
  const productsList = data?.map((item) => ({
    item_name: item.name,
    item_id: item.id,
    item_brand: 'Zenyum',
    item_category: `${item.catalogue?.category} - ${item.catalogue?.subCategory}`,
    item_variant: item?.color,
    price: item?.price ? item.price : null
    // quantity: product.quantity
  }));
  return {
    productList: productsList,
    currencyCode: data[0]?.catalogue?.currency
  };
};

const defaultPurchaseFormOptions = [
  { name: 'Zenyum', label: 'Zenyum' },
  { name: 'Shopee', label: 'Shopee' },
  { name: 'Lazada', label: 'Lazada' },
  { name: 'Guardian', label: 'Guardian' },
  { name: 'Others', label: 'Others' }
];

export const purchaseFromOptions = {
  sg: [
    { name: 'Zenyum Website', label: 'Zenyum Website' },
    { name: 'Shopee', label: 'Shopee' },
    { name: 'Lazada', label: 'Lazada' },
    { name: 'Tiktok', label: 'Tiktok' },
    { name: 'Guardian', label: 'Guardian' },
    { name: 'Watsons', label: 'Watsons' },
    { name: 'Fairprice', label: 'Fairprice' },
    { name: 'Cold Storage', label: 'Cold Storage' },
    { name: 'Popup', label: 'Popup' },
    { name: 'Others', label: 'Others' }
  ],
  my: [
    { name: 'Zenyum Website', label: 'Zenyum Website' },
    { name: 'Shopee', label: 'Shopee' },
    { name: 'Lazada', label: 'Lazada' },
    { name: 'Tiktok', label: 'Tiktok' },
    { name: 'Guardian', label: 'Guardian' },
    { name: 'Watsons', label: 'Watsons' },
    { name: 'Health Lane', label: 'Health Lane' },
    { name: 'Senheng', label: 'Senheng' },
    { name: 'Others', label: 'Others' }
  ],
  vn: [
    { name: 'Zenyum Website', label: 'Zenyum Website' },
    { name: 'Shopee', label: 'Shopee' },
    { name: 'Lazada', label: 'Lazada' },
    { name: 'Tiktok', label: 'Tiktok' },
    { name: 'Livestream', label: 'Livestream' },
    { name: 'Others', label: 'Others' }
  ],
  hk: [
    { name: 'Zenyum Website', label: 'Zenyum Website' },
    { name: 'HKTVMall', label: 'HKTVMall' },
    { name: 'Popup', label: 'Popup' },
    { name: 'Mannings', label: 'Mannings' },
    { name: 'YOHO', label: 'YOHO' },
    { name: 'Fortress', label: 'Fortress' },
    { name: 'Price HK', label: 'Price HK' },
    { name: 'Others', label: 'Others' }
  ],
  tw: [
    { name: 'Zenyum Website', label: 'Zenyum Website' },
    { name: 'Shopee', label: 'Shopee' },
    { name: 'Momo', label: 'Momo' },
    { name: 'PChome', label: 'PChome' },
    { name: 'Popup', label: 'Popup' },
    { name: 'Group Buys', label: 'Group Buys' },
    { name: 'Others', label: 'Others' }
  ],
  id: defaultPurchaseFormOptions,
  th: defaultPurchaseFormOptions,
  jp: defaultPurchaseFormOptions
};

export const getHelpOptions = [
  {
    img: '/img/icon-details.svg',
    link: 'how-to-find-serial-number',
    text: 'get_help_option_1',
    label: 'How to find a serial number'
  } /* ,
  {
    img: '/img/warranty/icon_support.svg',
    link: 'invalid-serial-number',
    text: 'get_help_option_2',
    label: 'Invalid serial number'
  } */
];

export const getHelpOptions2 = [
  {
    img: '/img/icon-details.svg',
    link: 'misplaced-warranty-card',
    text: 'get_help_option_1'
  }
];

export const warrantyActivationFormData = {
  fullName: 'Full Name',
  email: 'Email',
  proofOfPurchase: 'Proof of Purchase'
};

export const segmentMap = {
  'misplaced-warranty-card': 'Warranty Card Misplaced Claim',
  'invalid-serial-number': 'Warranty Invalid Serial Number'
};

export const pageTitleMap = {
  'misplaced-warranty-card': 'Misplaced Warranty Card',
  'invalid-serial-number': 'Invalid Serial Number'
};

export const successScreenMap = {
  'misplaced-warranty-card': 'Warranty Card Misplaced Claim Submitted Success',
  'invalid-serial-number': ' Warranty Serial Number Claim Submitted Success '
};

export const productSerialNumberCodes = {
  ZWFS: 'Waterflosser Pro ',
  ZSGO: 'Sonic Go Toothbrush',
  ZSON: 'ZenyumSonic Toothbrush'
};

export const customEventParam = {
  sg: {
    type1: ['SG Aligners IBM URLs', 'SG CP IBM URLs'],
    type2: ['SG Aligners IBM URLs'],
    type3: ['SG CP IBM URLs']
  },
  my: {
    type1: ['MY Aligners IBM URLs', 'MY CP IBM URLs'],
    type2: ['MY Aligners IBM URLs'],
    type3: ['MY CP IBM URLs']
  }
};

const bannerData = {
  homepageMobile: [],
  homepageDesktop: [],
  freshMobile: [],
  freshDesktop: [],
  sonicMobile: [],
  sonicDesktop: [],
  birthdayDesktop: [],
  birthdayMobile: [],
  whiteningDesktop: [],
  whiteningMobile: [],
  flossDesktop: [],
  flossMobile: [],
  toothbrushDesktop: [],
  toothbrushMobile: [],
  toothpasteMouthwashDesktop: [],
  toothpasteMouthwashMobile: [],
  shopallDesktop: [],
  shopallMobile: [],
  alignerDesktop: [],
  alignerMobile: []
};

const bannerImageTags = {
  SONIC: { mobile: 'sonicMobile', web: 'sonicDesktop' },
  HOME_PAGE: { mobile: 'homepageMobile', web: 'homepageDesktop' },
  FRESH: { mobile: 'freshMobile', web: 'freshDesktop' },
  BIRTHDAY: { mobile: 'birthdayMobile', web: 'birthdayDesktop' },
  WHITENING: { mobile: 'whiteningMobile', web: 'whiteningDesktop' },
  FLOSS: { mobile: 'flossMobile', web: 'flossDesktop' },
  ELECTRIC_TOOTHBRUSH: { mobile: 'toothbrushMobile', web: 'toothbrushDesktop' },
  TOOTHPASTES_AND_MOUTHWASH: {
    mobile: 'toothpasteMouthwashMobile',
    web: 'toothpasteMouthwashDesktop'
  },
  SHOP_ALL: { mobile: 'shopallMobile', web: 'shopallDesktop' },
  INVISIBLE_BRACES: { mobile: 'alignerMobile', web: 'alignerDesktop' }
};

export const fetchBannerConfig = (configRes) => {
  const banners = { ...bannerData };
  let birthdayBannerText = '';
  if (configRes) {
    configRes?.forEach((element) => {
      if (Object.keys(bannerImageTags).includes(element.productType)) {
        element.banners.forEach((val) => {
          const key = bannerImageTags[element.productType][val.bannerType];
          banners[key] = [...banners[key], val];
          if (element.productType === 'BIRTHDAY') {
            birthdayBannerText = val.text;
          }
        });
      }
    });
    return {
      banners,
      notifyMessage: configRes[0]?.announcement,
      notifyType: configRes[0]?.announcementType,
      birthdayBannerText,
      loaded: true
    };
  }
  return {};
};

export const igFeeds = (d, s, ide) => {
  const js = d.createElement(s);
  if (d.getElementById(ide)) {
    return;
  }
  js.id = ide;
  js.src = 'https://embedsocial.com/cdn/ht.js';
  d.getElementsByTagName('head')[0].appendChild(js);
};

export const removeEmbeds = (d, ide, ele) => {
  const js = d.getElementById(ide);
  if (js) js.remove();
  // eslint-disable-next-line no-param-reassign
  if (d.querySelector(`.${ele}`)) d.querySelector(`.${ele}`).innerHTML = '';
};

export const embedRatings = (d, s, ide) => {
  const js = d.createElement(s);
  if (d.getElementById(ide)) {
    return;
  }
  js.id = ide;
  js.src = 'https://embedsocial.com/embedscript/ri.js';
  d.getElementsByTagName('head')[0].appendChild(js);
};

export const checkRender = (condition, then, otherwise) => (condition ? then : otherwise);

export const SHOPIFY_URLS = {
  'sg-en': process.env.NEXT_PUBLIC_SHOPIFY_URL_SG_EN,
  'my-en': process.env.NEXT_PUBLIC_SHOPIFY_URL_MY_EN,
  'hk-en': process.env.NEXT_PUBLIC_SHOPIFY_URL_HK_EN,
  'hk-zh': process.env.NEXT_PUBLIC_SHOPIFY_URL_HK_ZH,
  'tw-zh': process.env.NEXT_PUBLIC_SHOPIFY_URL_TW_ZH,
  'vn-vi': process.env.NEXT_PUBLIC_SHOPIFY_URL_VN_VI,
  'th-th': '',
  'id-id': '',
  'jp-ja': process.env.NEXT_PUBLIC_SHOPIFY_URL_JP_JA
};

export const REDIRECTABLE_PAYMENT_MODES = new Set([
  PAYMENT_MODES_LIST.TWOC2P,
  PAYMENT_MODES_LIST.ATOME,
  PAYMENT_MODES_LIST.GRABPAY,
  PAYMENT_MODES_LIST.GRABPAYPOSTPAID,
  PAYMENT_MODES_LIST.GRABPAYLATER,
  PAYMENT_MODES_LIST.SHOPEE_PAY,
  PAYMENT_MODES_LIST.SPAYLATER
]);

export const DEFAULT_HOME_BANNERS = {
  sg: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  my: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  vn: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  hk: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  th: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  tw: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  id: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  },
  jp: {
    desktop: '/img/home-new/default_home_desktop_banner.jpg',
    mobile: '/img/home-new/default_home_mobile_banner.jpg'
  }
};

export const DEFAULT_INVISIBLE_BRACES_PAGE_BANNERS = {
  sg: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  my: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  vn: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  hk: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  th: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  tw: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  id: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  },
  jp: {
    desktop: '/img/invisible-braces/desktop_banner.png',
    mobile: '/img/invisible-braces/mobile_banner.png'
  }
};

export const dateConverter = {
  convertToDateAndMonth: (dateString, datesAndMonths) => { // Format: YYYY-MM-DD
    const date = moment(dateString);
    const monthFullName = dateConverter.getMonthName(date.format('MMMM'), 'full');
    const monthShortName = dateConverter.getMonthName(date.format('MMM'), 'short');
    const dayFullName = dateConverter.getDayName(date.format('dddd'), 'full');
    const dayShortName = dateConverter.getDayName(date.format('ddd'), 'short');
    const year = date.format('YYYY');
    return { date: `${date.format('D')}${datesAndMonths.day}`,
      dayShortName: datesAndMonths.daysShort[dayShortName],
      dayFullName: datesAndMonths.daysFull[dayFullName],
      monthFullName: datesAndMonths.monthsFull[monthFullName],
      monthShortName: datesAndMonths.monthsShort[monthShortName],
      year };
  },
  getMonthName: (monthIndex, type) => {
    if (type === 'full') {
      return monthsFullLokaliseKeys[monthIndex];
    } if (type === 'short') {
      return monthsShortLokaliseKeys[monthIndex];
    }
    return 'Invalid type';
  },
  getDayName: (dayIndex, type) => {
    if (type === 'full') {
      return daysFullLoklaiseKeys[dayIndex];
    } if (type === 'short') {
      return daysShortLoklaiseKeys[dayIndex];
    }
    return 'Invalid type';
  },
  // function to get a date by subtracting or adding n number of days from the current date
  // To add use type "ADD" , to subtract use type = "SUB"
  getDateByAddOrSubtractingDays: ({ noOfdays, type = 'ADD', customDay, datesAndMonths }) => {
    const currentDate = customDay ? moment(customDay) : moment();
    const previousDate = type === 'SUB' ? currentDate.subtract(noOfdays, 'days') : currentDate.add(noOfdays, 'days');
    const formatedPreviousDate = previousDate.format();
    const convertedFormatDate = dateConverter.convertToDateAndMonth(formatedPreviousDate, datesAndMonths);
    return { ...convertedFormatDate, formatedPreviousDate };
  },
  getDateDifferenceInNumberOfDays: (day1, day2 = '') => {
    const currentDate = moment();
    const day1Converted = moment(day1);
    const day2Converted = moment(day2);
    const difference = day2.length ? day2Converted.diff(day1Converted, 'days') : currentDate.diff(day1Converted, 'days');

    return difference;
  },
  isDateLesserThanCurrent: (date) => {
    const currentDate = moment();
    const inputDate = moment(date);
    return inputDate.isBefore(currentDate);
  }
};

export const getOrdinalSuffix = (number) => {
  if (number < 0) return '';
  if (number % 100 >= 10 && number % 100 <= 20) {
    return `${number}th`;
  }
  switch (number % 10) {
    case 1: return `First`;
    case 2: return `Second`;
    case 3: return `Third`;
    default: return `${number}th`;
  }
};

export const reverseDateLocales = ['hk-zh', 'tw-zh', 'jp-ja'];

export const localesWithExactDate = ['hk-zh', 'tw-zh', 'vn-vi'];

export const getDateByLocale = (data) => {
  const { date, month, year, locale } = data;
  if (reverseDateLocales.includes(locale)) return `${year} ${month} ${date}`;
  return `${date} ${month} ${year}`;
};

export const displayTime = (wearTime) => {
  const timeData = formatNumberToTime(wearTime);
  return `${timeData.hours}h ${timeData.minutes}m ${timeData.seconds}s`;
};
